import React, { useEffect, useRef } from 'react'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { RichText } from 'prismic'
import { useViewportScroll } from 'framer-motion'
import { useWindowHeight } from '@react-hook/window-size'

import Layout from 'layouts'
import useUIContext from 'context/ui'

import { Container } from 'components/ui/layout'
import { useScrollRig } from '@14islands/r3f-scroll-rig'
import AspectRatio from 'components/ui/aspect-ratio'
import Image from 'components/ui/image'
import SEO from 'components/seo'
import Slices from 'components/ui/slices'

import * as s from './BlogPostPage.module.css'

const cn = classNames.bind(s)

const BlogPostPage = ({ data }) => {
  const { scrollY } = useViewportScroll()
  const { reflow } = useScrollRig()
  const windowHeight = useWindowHeight(1000)
  const setHeaderTheme = useUIContext(s => s.setHeaderTheme)
  const imageWrapperRef = useRef(null)

  const page = data?.prismicBlogPost?.data || {}

  const {
    page_meta_title,
    page_meta_description,
    page_meta_thumbnail,
    display_date,
    title,
    thumbnail,
    category: categoryData,
    author: authorData,
    background_color,
    reading_time,
    hero_dark_mode,
    body,
  } = page

  const category = categoryData?.document?.data
  const author = authorData?.document?.data

  const thumbnailRatio = thumbnail?.dimensions?.height / thumbnail?.dimensions?.width || 0.66

  const handleScroll = value => {
    const imageTop = imageWrapperRef.current?.offsetTop || 0
    const imageHeight = imageWrapperRef.current?.clientHeight || 0

    const isOnTop = value <= Math.max(imageTop + imageHeight / 2 - windowHeight / 2, windowHeight / 3)

    const theme = hero_dark_mode && isOnTop ? 'dark' : 'light'

    document.documentElement.setAttribute('data-theme', theme)
    document.documentElement.style.background = isOnTop ? background_color : 'var(--color-background)'

    setHeaderTheme(theme)
  }

  useEffect(() => {
    if (hero_dark_mode) {
      setHeaderTheme('dark')
    }

    const timeout = setTimeout(reflow, 1500)

    return () => {
      clearTimeout(timeout)
      setHeaderTheme()
    }
  }, [])

  useEffect(() => {
    const unsubscribeScrollY = scrollY.onChange(handleScroll)

    return () => {
      unsubscribeScrollY()
    }
  }, [windowHeight])

  const theme = hero_dark_mode ? 'dark' : 'light'

  return (
    <Layout className={cn('aboutPage')} background={background_color} theme={theme} labCTA={false} fadeIn={true}>
      <SEO title={page_meta_title} description={page_meta_description} thumbnail={page_meta_thumbnail} />

      <div className={cn('hero')} data-theme={theme}>
        <Container>
          <div className={cn('heroWrapper')}>
            <span className={cn('info')}>
              {category?.name} | {dayjs(display_date).format('D MMM YYYY')}
            </span>

            <div className={cn('title')}>{RichText.render(title?.richText)}</div>

            <div className={cn('imageWrapper')} ref={imageWrapperRef}>
              <AspectRatio ratio={thumbnailRatio} className={cn('image')}>
                <Image image={thumbnail?.gatsbyImageData} alt={thumbnail?.alt} className={cn('image')} />
              </AspectRatio>
            </div>
          </div>
        </Container>
      </div>

      <Container className={cn('authorContainer')}>
        <div className={cn('author')}>
          <div className={cn('authorImageWrapper')}>
            <AspectRatio className={cn('authorImage')}>
              <Image image={author?.photo?.gatsbyImageData} alt={author?.photo?.alt} imgClassName={cn('authorImage')} />
            </AspectRatio>
          </div>

          <div className={cn('authorContent')}>
            <span className={cn('authorName')}>{author?.name}</span>
            <span className={cn('readingTime')}>{reading_time}</span>
          </div>
        </div>
      </Container>

      <div className={cn('slices')}>{body?.map(Slices)}</div>
    </Layout>
  )
}

export default BlogPostPage
