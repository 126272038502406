// extracted by mini-css-extract-plugin
export var hero = "BlogPostPage-module--hero--uR-sH";
export var heroWrapper = "BlogPostPage-module--heroWrapper--GEyq9";
export var info = "BlogPostPage-module--info--ZHzCt";
export var title = "BlogPostPage-module--title--pnyFU";
export var imageWrapper = "BlogPostPage-module--imageWrapper--TFEn6";
export var image = "BlogPostPage-module--image--MUPVa";
export var authorContainer = "BlogPostPage-module--authorContainer--frGuB";
export var author = "BlogPostPage-module--author--ySoYa";
export var authorImageWrapper = "BlogPostPage-module--authorImageWrapper--wQMRn";
export var authorImage = "BlogPostPage-module--authorImage--fIc16";
export var authorContent = "BlogPostPage-module--authorContent--pg8o9";
export var authorName = "BlogPostPage-module--authorName--S2Vmp";
export var readingTime = "BlogPostPage-module--readingTime--nLg71";
export var slices = "BlogPostPage-module--slices--IrzSd";