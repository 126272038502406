import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import BlogPostPage from 'views/blog-post/BlogPostPage'

export const query = graphql`
  query BlogPostPageQuery($uid: String) {
    prismicBlogPost(uid: { eq: $uid }, lang: { eq: "en-us" }) {
      _previewable
      ...BlogPostFragment
    }
  }
`

export default withPrismicPreview(BlogPostPage, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])
